<template>
  <div>
    <a-modal
      width="1024px"
      v-model="isShow"
      centered
      :mask-closable="false"
      :ok-button-props="{ props: { loading: submitting } }"
      title="新增账户"
      :body-style="{ color: 'red' }"
      @ok="handleSubmit"
    >
      <a-form
        :form="form"
        :label-col="{ span: 8 }"
        :wrapper-col="{ span: 15 }"
        class="simiot-compact-form"
        @submit="handleSubmit"
      >
        <a-row>
          <a-col :span="12">
            <span>基本信息</span>

            <a-form-item label="供应商">
              <a-select
                :loading="loadingSupplierOptions"
                show-search
                :filter-option="filterOption"
                v-decorator="[
                  'supplier_account_id',
                  { rules: [{ required: true, message: '请选择供应商' }] },
                ]"
                placeholder="请选择"
              >
                <a-select-option
                  v-for="supplier in supplierOptions"
                  :key="supplier.id"
                  :value="supplier.id"
                >
                  {{ supplier.name }}
                </a-select-option>
              </a-select>
            </a-form-item>

            <a-form-item label="账户名称">
              <a-input
                v-decorator="['name', {
                  normalize: this.$lodash.trim,
                  rules: [
                    { required: true, message: '请输入账户名称' },
                    { max: 50, message: '最多50个字符' }
                  ]
                }]"
              />
            </a-form-item>

            <a-form-item label="运营商种类">
              <a-select
                @change="selectCarrierType"
                v-decorator="['carrier_type', {
                  initialValue: 'china_mobile',
                  rules: [{ required: true, message: '请选择运营商种类' }
                  ]
                }]"
              >
                <a-select-option value="china_mobile">
                  中国移动
                </a-select-option>
                <a-select-option value="china_unicom">
                  中国联通
                </a-select-option>
                <a-select-option value="china_telecom">
                  中国电信
                </a-select-option>
                <a-select-option value="china_broadnet">
                  中国广电
                </a-select-option>
                <a-select-option value="mix_carrier">
                  融合运营商
                </a-select-option>
                <a-select-option value="international_carrier">
                  国际运营商
                </a-select-option>
              </a-select>
            </a-form-item>

            <a-form-item label="所属省市">
              <a-spin :spinning="loadingRegionOption">
                <a-cascader
                  :field-names="{ label: 'name', value: 'id', children: 'cities' }"
                  :options="regionOptions"
                  :show-search="{ regionFilter }"
                  placeholder="请选择省市"
                  v-decorator="['region', {
                    rules: [{ required: true, message: '请选择省市' }
                    ]
                  }]"
                />
              </a-spin>
            </a-form-item>

            <a-form-item label="联系人">
              <a-input
                v-decorator="['contact', {
                  normalize: this.$lodash.trim,
                  rules: [{ max: 50, message: '最多50个字符' }
                  ]
                }]"
              />
            </a-form-item>

            <a-form-item label="联系邮箱">
              <a-input
                v-decorator="['email', {
                  normalize: this.$lodash.trim,
                  rules: [
                    { pattern: /^[a-z0-9._%-]+@([a-z0-9-]+\.)+[a-z]{2,4}$/, message: '联系邮箱格式不正确' },
                  ]
                }]"
              />
            </a-form-item>

            <a-form-item label="联系电话">
              <a-input
                v-decorator="['phone_number', {
                  normalize: this.$lodash.trim,
                  rules: [
                    { pattern: /^1[3-9]\d{9}$/, message: '联系电话格式不正确' },
                  ]
                }]"
              />
            </a-form-item>

            <a-form-item label="签约主体">
              <a-select
                :loading="loadingContractingPartyOptions"
                show-search
                :filter-option="filterOption"
                v-decorator="[
                  'contracting_party_id',
                  { rules: [{ required: true, message: '请选择签约主体' }] },
                ]"
                placeholder="请选择"
              >
                <a-select-option
                  v-for="contracting_party in contractingPartyOptions"
                  :key="contracting_party.id"
                  :value="contracting_party.id"
                  :disabled="!contracting_party.effective"
                >
                  {{ contracting_party.name }}
                </a-select-option>
              </a-select>
            </a-form-item>

            <a-form-item label="短信功能">
              <a-checkbox
                :checked="isHasSmsChannel"
                v-decorator="['is_has_sms_channel']"
                @change="changeIsHasSmsChannel"
              />
            </a-form-item>
            <div v-show="isHasSmsChannel">
              <a-form-item label="短信接口">
                <a-select
                  :loading="loadingSmsChannelOptions"
                  show-search
                  :filter-option="filterOption"
                  v-decorator="['sms_channel_id', {
                    rules: [
                      { required: isHasSmsChannel, message: '请选择短信接口' }
                    ]
                  }]"
                  placeholder="请选择"
                >
                  <a-select-option
                    v-for="smsChannel in smsChannelOptions"
                    :key="smsChannel.id"
                    :value="smsChannel.id"
                    :disabled="!smsChannel.effective"
                  >
                    {{ smsChannel.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </div>

            <!--分割线-->
            <a-divider />

            <span>登录信息</span>

            <a-form-item label="运营商平台URL">
              <a-input
                v-decorator="['carrier_url', {
                  normalize: this.$lodash.trim,
                  rules: [{ max: 200, message: '最多200个字符' }
                  ]
                }]"
              />
            </a-form-item>

            <a-form-item label="运营商平台账号">
              <a-input
                v-decorator="['carrier_account', {
                  normalize: this.$lodash.trim,
                  rules: [{ max: 50, message: '最多50个字符' }
                  ]
                }]"
              />
            </a-form-item>

            <a-form-item label="运营商平台密码">
              <a-input
                v-decorator="['carrier_password', {
                  normalize: this.$lodash.trim,
                  rules: [{ max: 50, message: '最多50个字符' }
                  ]
                }]"
              />
            </a-form-item>

            <!--分割线-->
            <a-divider />
            <span>其他信息</span>

            <a-form-item label="计费周期" :required="true">
              <a-space>
                <a-select
                  placeholder="请选择计费周期"
                  style="width: 305px"
                  v-decorator="['charge_day', {
                    rules: [{ required: true, message: '请选择计费周期' }
                    ]
                  }]"
                >
                  <a-select-option :value="1">
                    1
                  </a-select-option>
                  <a-select-option :value="27">
                    27
                  </a-select-option>
                </a-select>
                <span>号</span>
              </a-space>
            </a-form-item>

            <a-form-item label="普通卡停机保号时长" :required="true">
              <a-space>
                <a-input-number
                  :min="1"
                  :max="10000000"
                  style="width: 305px"
                  placeholder="平台设置普通流量卡的停机保号时长"
                  v-decorator="['ordinary_stopped_months', {
                    rules: [
                      { required: true, message: '请输入普通卡停机保号时长' },
                      { type: 'integer', message: '请输入 1 - 10000000 之间的整数' }
                    ]
                  }]"
                />
                <span>月</span>
              </a-space>
            </a-form-item>

            <a-form-item label="NB卡停机保号时长" :required="true">
              <a-space>
                <a-input-number
                  :min="0"
                  :max="10000000"
                  style="width: 305px"
                  placeholder="平台设置NB卡的停机保号时长"
                  v-decorator="['nb_stopped_months', {
                    rules: [
                      { required: true, message: '请输入NB卡停机保号时长' },
                      { type: 'integer', message: '请输入 0 - 10000000 之间的整数' }
                    ]
                  }]"
                />
                <span>月</span>
              </a-space>
            </a-form-item>

            <a-form-item label="提请销卡日期" :required="true">
              <a-space>
                <a-select
                  placeholder="请选择提请销卡日期"
                  style="width: 305px"
                  v-decorator="['request_cancel_card_day', {
                    rules: [{ required: true, message: '请选择提请销卡日期' }
                    ]
                  }]"
                >
                  <a-select-option
                    v-for="index of 28"
                    :key="index"
                    :value="index"
                  >
                    {{ index }}
                  </a-select-option>
                </a-select>
                <span>号</span>
              </a-space>
            </a-form-item>

            <a-form-item label="备注">
              <a-textarea
                v-decorator="['remark', {
                  rules: [
                    { max: 200, message: '最多200个字符' },
                  ]
                }]"
                :auto-size="{ minRows: 3, maxRows: 5 }"
              />
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <span>接口信息</span>

            <a-form-item label="API类型">
              <a-select
                @change="selectApiType"
                v-decorator="['supplier_api_type', {
                  rules: [{ required: true, message: '请选择接口类型' }
                  ]
                }]"
              >
                <a-select-option :value="1">
                  运营商API
                </a-select-option>
                <a-select-option :value="2">
                  第三方API
                </a-select-option>
                <a-select-option :value="0">
                  无API
                </a-select-option>
              </a-select>
            </a-form-item>

            <div v-if="currentCarrierType === 'china_mobile' && isCarrierApiType">
              <china-mobile-api-account-form :form="form" />
            </div>

            <div v-else-if="currentCarrierType === 'china_unicom' && isCarrierApiType">
              <china-unicom-api-account-form />
            </div>

            <div v-else-if="currentCarrierType === 'china_telecom' && isCarrierApiType">
              <china-telecom-api-account-form />
            </div>
          </a-col>
        </a-row>
      </a-form>
    </a-modal>
  </div>
</template>
<script>

import { createCarrierAccount } from '@/api/carrier_account'
import { findRegionWithoutDistrict } from '@/api/region'
import { findContractingPartyOptions } from '@/api/contracting_party'
import { findSmsChannelOptionsByCarrierType } from '@/api/sms_channel'
import { findAllSupplierAccountOptions } from '@/api/common_options'

export default {
  name: 'NewCarrierAccount',
  props: {
    visible: {
      type: Boolean,
      required: true
    }
  },
  components: {
    ChinaMobileApiAccountForm: () => import('@/views/carrier_accounts/china_mobile/New'),
    ChinaUnicomApiAccountForm: () => import('@/views/carrier_accounts/china_unicom/New'),
    ChinaTelecomApiAccountForm: () => import('@/views/carrier_accounts/china_telecom/New')
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: 'carrier_account' }),
      submitting: false,
      currentCarrierType: 'china_mobile',
      currentApiType: '',
      isCarrierApiType: false,
      regionOptions: [],
      loadingRegionOption: true,
      contractingPartyOptions: [],
      loadingContractingPartyOptions: true,
      supplierOptions: [],
      loadingSupplierOptions: true,
      isHasSmsChannel: false,
      loadingSmsChannelOptions: false,
      smsChannelOptions: []
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  created() {
    this.fetchRegionOptions()
    this.fetchcontractingParties()
    this.findSupplierAccountOptions()
  },
  methods: {
    changeIsHasSmsChannel(e) {
      this.isHasSmsChannel = e.target.checked
      this.form.resetFields('sms_channel_id')
      this.fetchSmsChannelOptions(this.currentCarrierType)
    },

    // 加载省市
    fetchRegionOptions() {
      this.loadingRegionOption = true
      findRegionWithoutDistrict().then((res) => {
        if (res.code === 0) {
          this.regionOptions = res.data
        }
        this.loadingRegionOption = false
      })
    },

    // 加载签约主体
    fetchcontractingParties() {
      this.loadingContractingPartyOptions = true
      findContractingPartyOptions().then((res) => {
        if (res.code === 0) {
          this.contractingPartyOptions = res.data
        }
        this.loadingContractingPartyOptions = false
      })
    },

    // 加载供应商
    findSupplierAccountOptions() {
      this.loadingSupplierOptions = true
      findAllSupplierAccountOptions().then((res) => {
        if (res.code === 0) {
          this.supplierOptions = res.data
        }
        this.loadingSupplierOptions = false
      })
    },

    // 加载短信接口
    fetchSmsChannelOptions(carrierType) {
      if (this.smsChannelOptions.length === 0) {
        this.loadingSmsChannelOptions = true
        findSmsChannelOptionsByCarrierType(Object.assign({}, { carrier_type: carrierType })).then((res) => {
          if (res.code === 0) {
            this.smsChannelOptions = res.data
          }
          this.loadingSmsChannelOptions = false
        })
      }
    },

    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },

    regionFilter(inputValue, path) {
      return path.some(option => option.name.toLowerCase().indexOf(inputValue.toLowerCase()) > -1)
    },

    handleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }

      this.form.validateFields((err, values) => {
        if (!err) {
          this.submitting = true
          const data = {
            ...values,
            ordinary_stopped_months: [null, undefined].includes(values.ordinary_stopped_months) ? -1 : values.ordinary_stopped_months,
            nb_stopped_months: [null, undefined].includes(values.nb_stopped_months) ? -1 : values.nb_stopped_months
          }
          createCarrierAccount(data).then((res) => {
            if (res.code === 0) {
              // 关闭模态框
              this.isShow = false
              // 告知父组件已完成
              this.$emit('completed')
            }
            this.submitting = false
          })
        }
      })
    },

    selectCarrierType(value) {
      this.currentCarrierType = value
      this.smsChannelOptions = []
      if (this.isHasSmsChannel) {
        this.form.resetFields('sms_channel_id')
        this.fetchSmsChannelOptions(value)
      }
    },

    selectApiType(value) {
      this.currentApiType = value
      if (this.currentApiType === 1) {
        this.isCarrierApiType = true
      } else {
        this.isCarrierApiType = false
      }
    }
  }
}
</script>

<style scoped>
</style>

import request from '@/utils/request'

// 创建短信接口
export function createSmsChannel(data) {
  return request({
    url: `/sms_channels`,
    method: 'post',
    data
  })
}

// 查询短信接口
export function findSmsChannels(params) {
  return request({
    url: `/sms_channels`,
    method: 'get',
    params
  })
}

// 更新短信接口有效性
export function updateSmsChannelEffective(smsChannelId, data) {
  return request({
    url: `/sms_channels/${smsChannelId}/effective`,
    method: 'patch',
    data
  })
}

// 更新短信接口有效性
export function findSmsChannelForm(smsChannelId) {
  return request({
    url: `/sms_channels/${smsChannelId}/form`,
    method: 'get'
  })
}

// 更新短信接口
export function updateSmsChannel(smsChannelId, data) {
  return request({
    url: `/sms_channels/${smsChannelId}`,
    method: 'put',
    data
  })
}

// 查询短信接口
export function findSmsChannelOptionsByCarrierType(params) {
  return request({
    url: `/sms_channels/options`,
    method: 'get',
    params
  })
}
